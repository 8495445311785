@font-face {
  font-family: "Consolas";
  src: url(./Consolas.ttf);
}

@font-face {
  font-family: "CourierPrime";
  src: url(./CourierPrime.ttf);
}

@font-face {
  font-family: "RobotoMono";
  src: url(./RobotoMono.ttf);
}

@font-face {
  font-family: "VRC";
  src: url(./VRC.ttf);
}
